import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  eachHourOfInterval,
  subHours,
  addHours,
  subMinutes
} from 'date-fns/esm';
import {
  onWebsocketMessageDispatchUpdateEntities,
  onWebsocketMessageDispatchAddRemoveEntities,
  MergeStrategy,
  EntityOps,
  isEntityOp,
  errorOf, selectEntityList
} from '@zerops/zef/entities';
import { ZefSnackService } from '@zerops/zef/snack';
import { Action, select, Store } from '@ngrx/store';
import { ServiceStackTypeCategories, ServiceStackStatuses, ServiceStack } from '@zerops/models/service-stack';
import { UserEntity } from '@zerops/zerops/core/user-base';
import { StatisticsEntities, StatisticsGroupBy } from '@zerops/models/resource-statistics';
import { deletionWarningDialogOpen } from '@zerops/zerops/feature/deletion-warning-dialog';
import { loadCurrentStatistics } from '@zerops/zerops/core/resource-statistics-base';
import { transactionDebitGroupPrefillData } from '@zerops/zerops/core/transaction-debit-base';
import {
  TransactionDebitGroupItem,
  TransactionGroupBy,
  TRANSACTION_GROUP_RANGE
} from '@zerops/models/transaction-debit';
import difference from 'lodash-es/difference';
import omit from 'lodash-es/omit';
import {
  ImportExportDialogModes,
  importExportDialogOpen,
  IMPORT_EXPORT_DIALOG_FEATURE_NAME
} from '@zerops/zerops/feature/import-export-dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
// explicit import due to cyclic dependency problems
import { ApiEntityKeys, AppState } from '@zerops/zerops/app';
import {
  switchMap,
  map,
  catchError,
  filter,
  mergeMap,
  withLatestFrom,
  pairwise,
  tap
} from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  addServiceStack,
  addServiceStackFail,
  addServiceStackSuccess,
  serviceStackStart,
  serviceStackStop,
  serviceStackRestart,
  serviceStackReload,
  serviceStackStartSuccess,
  serviceStackStopSuccess,
  serviceStackRestartSuccess,
  serviceStackReloadSuccess,
  serviceStackStartFail,
  serviceStackStopFail,
  serviceStackRestartFail,
  serviceStackReloadFail,
  enableSubdomainAccess,
  enableSubdomainAccessSuccess,
  enableSubdomainAccessFail,
  disableSubdomainAccess,
  disableSubdomainAccessSuccess,
  disableSubdomainAccessFail,
  serviceStackModifyInternalPorts,
  serviceStackModifyInternalPortsSuccess,
  serviceStackModifyInternalPortsFail,
  connectDisconnectSharedStorage,
  connectDisconnectSharedStorageFail,
  connectDisconnectSharedStorageSuccess,
  integrateExternalRepository,
  integrateExternalRepositorySuccess,
  integrateExternalRepositoryFail,
  removeExternalRepositoryIntegration,
  removeExternalRepositoryIntegrationSuccess,
  removeExternalRepositoryIntegrationFail,
  updateObjectStorageSettings,
  updateObjectStorageSettingsSuccess,
  updateObjectStorageSettingsFail,
  updateServiceStack,
  updateServiceStackFail,
  updateServiceStackSuccess,
  serviceStackExport,
  serviceStackExportSuccess,
  serviceStackExportFail,
  serviceStackImport,
  serviceStackImportSuccess,
  serviceStackImportFail,
  adjustServiceStackAutoscaling,
  adjustServiceStackAutoscalingSuccess,
  adjustServiceStackAutoscalingFail,
  deleteBuildCache,
  deleteBuildCacheSuccess,
  deleteBuildCacheFail,
  deleteRuntimePrepareCache,
  deleteRuntimePrepareCacheSuccess,
  deleteRuntimePrepareCacheFail,
  getContainerFile,
  getContainerFileSuccess,
  getContainerFileFail,
  getBackups,
  getBackupsSuccess,
  getBackupsFail,
  createBackup,
  createBackupSuccess,
  createBackupFail,
  setBackupFrequency,
  backupDownload,
  backupDownloadSuccess,
  backupDownloadFail,
  deleteBackup,
  deleteBackupSuccess,
  deleteBackupFail,
  setBackupFrequencySuccess,
  setBackupFrequencyFail
}
from './service-stack-base.action';
import { ServiceStackBaseApi } from './service-stack-base.api';
import { ServiceStackEntity } from './service-stack-base.entity';
import { FEATURE_NAME, ServiceStackOperationTypes } from './service-stack-base.constant';

@Injectable()
export class ServiceStackBaseEffect {

  private _clientId$ = this._userEntity.activeClientId$.pipe(filter((d) => !!d));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._clientId$.pipe(
    map((clientId) => this._serviceStackEntity.updateSubscribe(clientId)
  )));

  private _setupListStreamSubscription$ = createEffect(() => this._clientId$.pipe(
    map((clientId) => this._serviceStackEntity.listSubscribe(
      clientId,
      FEATURE_NAME,
      undefined,
      {
        handleGlobally: false
      }
    ))
  ));

  private _setupAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._serviceStackEntity)
  ));


  private _prefillTransactionDebitForProjects$ = createEffect(() => this._store.pipe(
    // we only need ids, so we are taking them raw from the state
    select(selectEntityList(this._serviceStackEntity.entityName)),
    pairwise(),
    // checking if there are new ids
    map(([ prevIds, currIds ]) => difference(currIds, prevIds)),
    mergeMap((ids: string[]) => {

      const groupRange = TRANSACTION_GROUP_RANGE.last24h;

      const items = ids.reduce((arr: any, id: string) => {

        arr.push(...eachHourOfInterval({
          start: addHours(groupRange.range.from, 1),
          end: groupRange.range.to
        }).map((itm: Date) => ({
          sumTotalPrice: 0,
          from: subHours(itm, 1),
          stackId: id,
          cpu: 0,
          disc: 0,
          ram: 0,
          till: subMinutes(itm, 1)
        })));

        return arr;

      }, []) as TransactionDebitGroupItem[];

      return [
        transactionDebitGroupPrefillData({
          timeGroupBy: groupRange.timeGroupBy,
          groupBy: TransactionGroupBy.Metric,
          limit: groupRange.limit,
          from: groupRange.range.from,
          till: groupRange.range.to,
          key: groupRange.key,
          items
        })
      ];

    })
  ));

  private _onAddServiceStackReloadCurrentStatistics$ = createEffect(() => this._actions$.pipe(
    filter((action) => isEntityOp(this._serviceStackEntity.entityName, EntityOps.UpdateCacheDone, action)),
    // TODO: action interface
    // get action payload
    map((action: any): { result: string[]; entities: any; } => action.data?.items),
    // check if there were any updated entities
    filter((d) => !!d?.result?.length),
    // find those entities whom status is active or creating
    map((d) => d.result
      .reduce((arr, id) => {
        arr.push(d.entities[ApiEntityKeys.ServiceStack][id]);
        return arr;
      }, [])
    ),
    // gather previous value
    pairwise(),
    // return any entities that changed to active or stopped
    map(([ prev, next ]) => {
      const prevHashMap = prev.reduce((obj, itm) => {
        obj[itm.id] = itm.status;
        return obj;
      }, {});

      return next?.filter((itm) => (itm.status === ServiceStackStatuses.Active
        && prevHashMap[itm.id] !== ServiceStackStatuses.Active)
        || (itm.status === ServiceStackStatuses.Stopped
          && prevHashMap[itm.id] !== ServiceStackStatuses.Stopped))
      || [];
    }),
    filter((d) => !!d?.length),
    withLatestFrom(this._clientId$),
    map(([ _, clientId ]) => loadCurrentStatistics({
      data: {
        clientId,
        groupBy: StatisticsGroupBy.Service,
        statisticsEntity: StatisticsEntities.ServiceStack
      }
    }))
  ));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(
      this._serviceStackEntity,
      {
        zefEntityMergeStrategy: {
          ports: MergeStrategy.KeepNew,
          connectedStacks: MergeStrategy.KeepNew
        }
      }
    )
  ));

  private _onAddServiceStack$ = createEffect(() => this._actions$.pipe(
    ofType(addServiceStack),
    mergeMap((action) => this._api
      .add$(action.data)
      .pipe(
        map((res) => addServiceStackSuccess(res, action)),
        catchError((res) => of(addServiceStackFail(res, action)))
      )
    )
  ));

  private _onAddServiceStackSuccess$ = this._actions$.pipe(ofType(addServiceStackSuccess));

  private _onAddSharedStorageSuccess$ = createEffect(() => this._onAddServiceStackSuccess$.pipe(
    filter((d) => d?.data?.serviceStackTypeInfo.serviceStackTypeCategory === ServiceStackTypeCategories.SharedStorage
      && d?.originalAction.data.storageStackIds.value.length
    ),
    mergeMap(({ data, originalAction }) => originalAction.data.storageStackIds.value
      .reduce((arr: Action[], stackId: string) => {
        arr.push(connectDisconnectSharedStorage({
          stackId,
          sharedStorageId: data.id,
          type: 'connect'
        }));
        return arr;
      }, []) as Observable<Action>
    )
  ));

  private _onAddServiceStackUpdateCache = createEffect(() => this._onAddServiceStackSuccess$.pipe(
    map((d) => this._serviceStackEntity.addToCache([ d.data ]))
  ));

  private _onConnectDisconnectSharedStorage$ = createEffect(() => this._actions$.pipe(
    ofType(connectDisconnectSharedStorage),
    mergeMap((action) => this._api
      .connectDisconnectSharedStorage$(action.data.stackId, action.data.sharedStorageId, action.data.type)
      .pipe(
        map((response) => connectDisconnectSharedStorageSuccess(response, action)),
        catchError((err) => of(connectDisconnectSharedStorageFail(err, action)))
      )
    )
  ));

  // -- open deploy settings dialog after adding a new user service
  // private _onAddServiceStackSuccessOpenNewServiceDialog$ = createEffect(() => this._onAddServiceStackSuccess$.pipe(
  //   filter((d) => d?.data?.status === ServiceStackStatuses.New
  //     && d?.data?.serviceStackTypeInfo.serviceStackTypeCategory === ServiceStackTypeCategories.User
  //   ),
  //   map(({ data }) => newServiceDialogOpen(data)),
  // ));

  // -- start, stop, restart, reload
  private _onTriggerServiceStackOperation$ = createEffect(() => this._actions$.pipe(
    ofType(
      serviceStackStart,
      serviceStackStop,
      serviceStackRestart,
      serviceStackReload
    ),
    mergeMap((action) => this._api
      .triggerServiceStackOperation$(action.data.id, action.data.type)
      .pipe(
        map((response) => {
          switch (action.data.type) {
            case ServiceStackOperationTypes.Start: return serviceStackStartSuccess(response, action);
            case ServiceStackOperationTypes.Stop: return serviceStackStopSuccess(response, action);
            case ServiceStackOperationTypes.Restart: return serviceStackRestartSuccess(response, action);
            case ServiceStackOperationTypes.Reload: return serviceStackReloadSuccess(response, action);
          }
        }),
        catchError((err) => {
          switch (action.data.type) {
            case ServiceStackOperationTypes.Start: return of(serviceStackStartFail(err, action));
            case ServiceStackOperationTypes.Stop: return of(serviceStackStopFail(err, action));
            case ServiceStackOperationTypes.Restart: return of(serviceStackRestartFail(err, action));
            case ServiceStackOperationTypes.Reload: return of(serviceStackReloadFail(err, action));
          }
        })
      )
    )
  ));

  // -- get a container file, as the Nginx config, through the file browser api
  onGetContainerFile$ = createEffect(() => this._actions$.pipe(
    ofType(getContainerFile),
    switchMap((action) => this._api
      .getContainerFile$(action.data.serviceStackId, action.data.containerId, action.data.filePath)
      .pipe(
        map((response) => getContainerFileSuccess(
          window.atob(response.content),
          action
        )),
        catchError((err) => of(getContainerFileFail(err, action)))
      )
    )
  ));

  // -- enable subdomain access
  private _enableSubdomainAccess$ = createEffect(() => this._actions$.pipe(
    ofType(enableSubdomainAccess),
    switchMap((action) => this._api
      .enableSubdomainAccess$(action.data.id)
      .pipe(
        map((response) => enableSubdomainAccessSuccess(
          response,
          action
        )),
        catchError((err) => of(enableSubdomainAccessFail(err, action)))
      )
    )
  ));

  // -- disable subdomain access
  private _disableSubdomainAccess$ = createEffect(() => this._actions$.pipe(
    ofType(disableSubdomainAccess),
    switchMap((action) => this._api
      .disableSubdomainAccess$(action.data.id)
      .pipe(
        map((response) => disableSubdomainAccessSuccess(
          response,
          action
        )),
        catchError((err) => of(disableSubdomainAccessFail(err, action)))
      )
    )
  ));

  // -- modify internal ports
  private _onServiceStackModifyInternalPorts$ = createEffect(() => this._actions$.pipe(
    ofType(serviceStackModifyInternalPorts),
    switchMap((action) => this._api
      .modifyInternalPorts$(action.data.serviceStackId, action.data.ports)
      .pipe(
        map((response) => serviceStackModifyInternalPortsSuccess(response, action)),
        catchError((err) => of(serviceStackModifyInternalPortsFail(err, action)))
      )
    )
  ));

  // -- update object storage size
  private _onUpdateObjectStorageSize$ = createEffect(() => this._actions$.pipe(
    ofType(updateObjectStorageSettings),
    switchMap((action) => this._api
      .updateObjectStorageSettings$(
        action.data.id,
        action.data.diskGBytes,
        action.data.policy,
        action.data.rawPolicy
      )
      .pipe(
        map((response) => updateObjectStorageSettingsSuccess(response, action)),
        catchError((err) => of(updateObjectStorageSettingsFail(err, action)))
      )
    )
  ));

  // -- external repository integration
  private _onIntegrateExternalRepository$ = createEffect(() => this._actions$.pipe(
    ofType(integrateExternalRepository),
    switchMap((action) => this._api
      .externalRepositoryIntegration$(action.data.id, action.data)
      .pipe(
        map((response) => integrateExternalRepositorySuccess(response, action)),
        catchError((err) => of(integrateExternalRepositoryFail(err, action)))
      )
    )
  ));

  // -- remove external repository integration
  private _onRemoveExternalRepositoryIntegration$ = createEffect(() => this._actions$.pipe(
    ofType(removeExternalRepositoryIntegration),
    switchMap((action) => this._api
      .externalRepositoryIntegration$(action.data.id, {
        gitLabIntegration: null,
        gitHubIntegration: null
      })
      .pipe(
        map((response) => removeExternalRepositoryIntegrationSuccess(response, action)),
        catchError((err) => of(removeExternalRepositoryIntegrationFail(err, action)))
      )
    )
  ));

  private _onGetOneError$ = createEffect(() => this._actions$.pipe(
    errorOf<ServiceStack>(this._serviceStackEntity.getOne),
    filter(({ meta: { zefError } }) => zefError.code === 'serviceStackNotFound'),
    map(() => deletionWarningDialogOpen({ entity: ApiEntityKeys.ServiceStack }))
  ));

  // -- stack update
  private _onUpdateServiceStack$ = createEffect(() => this._actions$.pipe(
    ofType(updateServiceStack),
    switchMap((action) => this._api
      .stackUpdate$(action.data?.id, action.data?.typeVersion, action.data?.data)
      .pipe(
        map((res) => updateServiceStackSuccess(res, action)),
        catchError((err) => of(updateServiceStackFail(err, action)))
      )
    )
  ));

  private _onServiceStackExport$ = createEffect(() => this._actions$.pipe(
    ofType(serviceStackExport),
    switchMap((action) => this._api
      .serviceStackExport$(action.data)
      .pipe(
        map((res) => serviceStackExportSuccess(res.yaml, action)),
        catchError((res) => of(serviceStackExportFail(res, action)))
      )
    )
  ));

  private _onServiceStackExportSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(serviceStackExportSuccess),
    map(({ data }) => importExportDialogOpen({ yaml: data, mode: ImportExportDialogModes.Export, entity: 'service-stack' }))
  ));

  private _onServiceStackImport$ = createEffect(() => this._actions$.pipe(
    ofType(serviceStackImport),
    switchMap((action) => this._api
      .serviceStackImport$(action.data.yaml, action.data.projectId)
      .pipe(
        map(() => serviceStackImportSuccess(undefined, action)),
        catchError((res) => of(serviceStackImportFail(res, action)))
      )
    )
  ));

  private _onServiceStackImportSuccess$ = this._actions$.pipe(
    ofType(serviceStackImportSuccess)
  );

  private _onServiceStackImportSuccessNotification$ = createEffect(() => this._onServiceStackImportSuccess$.pipe(
    switchMap(() => this._snack.success$({ text: 'Service was imported successfully' }))
  ), { dispatch: false });

  private _onServiceStackImportSuccessDialogClose$ = createEffect(() => this._onServiceStackImportSuccess$.pipe(
    map(() => zefDialogClose({
      key: IMPORT_EXPORT_DIALOG_FEATURE_NAME
    }))
  ));

  private _onAdjustServiceStackAutoscaling$ = createEffect(() => this._actions$.pipe(
    ofType(adjustServiceStackAutoscaling),
    switchMap((action) => {

      const customAutoscaling = action.data.mode === 'NON_HA'
        ? omit(action.data.customAutoscaling, ['horizontalAutoscaling'])
        : action.data.customAutoscaling;

      return this._api
        .adjustAutoscaling$(
          action.data.serviceStackId,
          action.data.mode,
          customAutoscaling
        )
        .pipe(
          map((res) => adjustServiceStackAutoscalingSuccess(res, action)),
          catchError((res) => of(adjustServiceStackAutoscalingFail(res, action)))
        )
    })
  ));

  // -- clear build cache
  private _onDeleteBuildCache$ = createEffect(() => this._actions$.pipe(
    ofType(deleteBuildCache),
    switchMap((action) => this._api
      .deleteBuildCache$(action.data.id).pipe(
        map(() => deleteBuildCacheSuccess(undefined, action)),
        catchError((res) => of(deleteBuildCacheFail(res, action)))
      )
    )
  ));

  // -- clear runtime prepare cache
  private _onDeleteRuntimePrepareCache$ = createEffect(() => this._actions$.pipe(
    ofType(deleteRuntimePrepareCache),
    switchMap((action) => this._api
      .deleteRuntimePrepareCache$(action.data.id).pipe(
        map(() => deleteRuntimePrepareCacheSuccess(undefined, action)),
        catchError((res) => of(deleteRuntimePrepareCacheFail(res, action)))
      )
    )
  ));

  private _onDeleteBuildCacheSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(deleteBuildCacheSuccess),
    switchMap(() => this._snack.success$({ text: 'Build cache invalidated successfully' }))
  ), { dispatch: false });

  private _onDeleteRuntimePrepareCacheSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(deleteRuntimePrepareCacheSuccess),
    switchMap(() => this._snack.success$({ text: 'Runtime prepare cache invalidated successfully' }))
  ), { dispatch: false });

  // -- get backups
  private _onGetBackups$ = createEffect(() => this._actions$.pipe(
    ofType(getBackups),
    switchMap((action) => this._api
      .getBackups$(action.data.id).pipe(
        map((res) => getBackupsSuccess(res.files, action)),
        catchError((res) => of(getBackupsFail(res, action)))
      )
    )
  ));

  // -- create backup
  private _onCreateBackup$ = createEffect(() => this._actions$.pipe(
    ofType(createBackup),
    switchMap((action) => this._api
      .createBackup$(action.data.id).pipe(
        map((res) => createBackupSuccess(res, action)),
        catchError((res) => of(createBackupFail(res, action)))
      )
    )
  ));

  private _onCreateBackupSuccessReloadList$ = createEffect(() => this._actions$.pipe(
    ofType(createBackupSuccess),
    map(({ originalAction }) => getBackups({ id: originalAction.data.id }))
  ));

  private _onCreateBackupSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(createBackupSuccess),
    switchMap(({ data }) => this._snack.success$({
      text: data?.async
        ? 'Backup is running on background, try refreshing the page later..'
        : 'Backup succesfully created, refreshing list..'
    }, {
      duration: data?.async ? 2500 : 1500
    }))
  ), { dispatch: false });

  // -- set backup frequency
  private _onSetBackupFrequency$ = createEffect(() => this._actions$.pipe(
    ofType(setBackupFrequency),
    switchMap((action) => this._api
      .setBackupFrequency$(action.data.serviceId, action.data.frequency).pipe(
        map((res) => setBackupFrequencySuccess(res, action)),
        catchError((res) => of(setBackupFrequencyFail(res, action)))
      )
    )
  ));

  // -- create backup
  private _onDownloadBackup$ = createEffect(() => this._actions$.pipe(
    ofType(backupDownload),
    switchMap((action) => this._api
      .downloadBackup$(action.data.serviceId, action.data.id).pipe(
        map((res) => backupDownloadSuccess({
          contents: res.body,
          filename: action.data.filename
        }, action)),
        catchError((res) => of(backupDownloadFail(res, action)))
      )
    )
  ));

  private _onDownloadBackupSuccessDownloadFile$ = createEffect(() => this._actions$.pipe(
    ofType(backupDownloadSuccess),
    tap(({ data }) => {

      const url = URL.createObjectURL(data.contents);
      const a = document.createElement('a');
      a.href = url;
      a.download = data.filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

    })
  ), { dispatch: false })

  // -- delete backup
  private _onDeleteBackup$ = createEffect(() => this._actions$.pipe(
    ofType(deleteBackup),
    switchMap((action) => this._api
      .deleteBackup$(action.data.serviceId, action.data.id).pipe(
        map(() => deleteBackupSuccess(action.data, action)),
        catchError((res) => of(deleteBackupFail(res, action)))
      )
    )
  ));

  private _onDeleteBackupSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(deleteBackupSuccess),
    switchMap(() => this._snack.success$({
      text: 'Backup succesfully deleted'
    }, {
      duration: 1500
    }))
  ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _api: ServiceStackBaseApi,
    private _snack: ZefSnackService,
    private _store: Store<AppState>,
    private _serviceStackEntity: ServiceStackEntity,
    private _userEntity: UserEntity
  ) { }
}
